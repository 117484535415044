import React, { useState } from "react"
import "../assets/styles/index.scss"
import Head from "../components/global/head"
import ModalVideo from "react-modal-video"

import banner2x from "../assets/images/dx3k/banner@2x.jpg"
import block1 from "../assets/images/dx3k/block1.jpg"
import block2 from "../assets/images/dx3k/block2.jpg"
import block3 from "../assets/images/dx3k/block3.jpg"
import block4 from "../assets/images/dx3k/block4.jpg"
import block5 from "../assets/images/dx3k/block5.jpg"
import block6 from "../assets/images/dx3k/block6.jpg"
import block7 from "../assets/images/dx3k/block7.jpg"
import block8 from "../assets/images/dx3k/block8.jpg"
import block9 from "../assets/images/dx3k/block9.jpg"
import video1 from "../assets/images/dx3k/video1.jpg"

const FourNTwenty = () => {
  const [showFullStudy, setShowFullStudy] = useState(false)
  const [showVideo, setShowVideo] = useState(false)

  const handleOpenVideo = (e) => {
    e.preventDefault()
    setShowVideo(true)
  }
  const handleCloseVideo = () => {
    setShowVideo(false)
  }
  return (
    <>
      <Head
        description="Hardhat is an independent creative agency helping brands capitalise on the why, when, where, what and how of human behaviour."
        title="Case Study - DEAKIN FLIGHT DX3K"
        url="https://hardhat.com.au/dx3k"
        image="https://hardhat.com.au/images/og.jpg"
        bodyClassName="old-case-studies"
      />
      <div>
        <div className="container case-study dark-header">
          <section className="hhd-head-banner js-banner-intro-animation">
            <h1>DEAKIN FLIGHT DX3K</h1>
            <div className="divider black center" />
          </section>
          <section className="hhd-head-banner no-padding js-banner-intro-animation">
            <div
              className="banner-image"
              style={{
                backgroundImage: `url(${banner2x})`,
              }}
            >
              DEAKIN FLIGHT DX3K
            </div>
          </section>
          <section className="hhd-case-intro js-fade-in-animation">
            <div className="hhd-case-intro-text">
              <p>
                For Open Day 2016, we created a world first, shared VR
                experience that helped thousands of prospective students tour
                Deakin’s four campuses in under two minutes.
              </p>
              <p>
                Taking inspiration from Willy Wonka’s glass elevator in the
                original Charlie and the Chocolate Factory, we captured 360
                degree drone footage from across Deakin’s campuses and stitched
                it together to create a seamless, immersive ‘in flight’
                experience. From there, we built a physical structure large
                enough for a dozen friends to stand in, and mapped the footage
                to all sides and the floor, effectively placing our audience
                ‘inside the drone’ for the flight of their lives.
              </p>
              <p>
                Post Open Day, DX3K has been extended into a headset experience,
                utilised daily by Deakin’s student recruitment team on their
                visits to high schools around the country.
              </p>
              <hr />
            </div>
            <div className="hhd-case-awards">
              <div>MASHIES WINNER: BEST USE OF VIRTUAL/AUGMENTED REALITY</div>
              <div>
                AIMIA AWARD FINALIST: BEST DIGITAL ADVERTISING OR COMMUNICATIONS
                (LEARNING AND EDUCATION)
              </div>

              <div>
                AIMIA AWARD FINALIST: BEST DIGITAL ADVERTISING OR COMMUNICATIONS
                (BEST USE OF TECHNOLOGY)
              </div>

              <div>MUMBRELLA AWARD FOR INNOVATION: HIGHLY COMMENDED</div>

              <div>
                MELBOURNE DESIGN AWARDS SILVER: MARKETING - BRANDED EXPERIENCE
              </div>
              <div>AC&E AWARD FINALIST: USE OF NEW TECHNOLOGIES</div>
            </div>
          </section>
          <section className="hhd-case-image-block js-fade-in-animation">
            <div className="block-half">
              <img src={`${block5}`} width="100%" />
            </div>
            <div className="block-half">
              <div className="block-half">
                <img src={`${block1}`} width="100%" />
              </div>
              <div className="block-half">
                <img src={`${block2}`} width="100%" />
              </div>
              <div className="block-half">
                <img src={`${block3}`} width="100%" />
              </div>
              <div className="block-half">
                <img src={`${block4}`} width="100%" />
              </div>
            </div>
          </section>
          <section className="hhd-case-image-block js-fade-in-animation no-padding">
            <div className="block-half">
              <div className="block-full">
                <img src={`${block7}`} width="100%" />
              </div>
              <div className="block-half">
                <img src={`${block8}`} width="100%" />
              </div>
              <div className="block-half">
                <img src={`${block9}`} width="100%" />
              </div>
            </div>
            <div className="block-half">
              <img src={`${block6}`} width="100%" />
            </div>
          </section>

          <section className="hhd-case-bigscreen-video js-hhd-video no-padding">
            <button className="play-vid" onClick={handleOpenVideo}>
              {" "}
              <div className="js-video-container latest-work-video-container">
                <img src={video1} width="100%" />
              </div>
              <span className="close-vid">
                <a href="#" className="close-vid-btn">
                  <i className="icon--stop" />
                </a>
              </span>
            </button>

            <ModalVideo
              channel="youtube"
              isOpen={showVideo}
              videoId="_8HEGM_oJ0k"
              onClose={handleCloseVideo}
            />
          </section>
        </div>
      </div>
    </>
  )
}
export default FourNTwenty
